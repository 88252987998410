var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-8", style: { maxWidth: "1318px" } },
    [
      _c("v-breadcrumbs", {
        staticClass: "pa-0 ma-0",
        attrs: { items: _vm.breadcrumbs },
      }),
      _c("div", { staticClass: "font-weight-bold text-h4" }, [
        _vm._v(_vm._s(this.personalLoan.title)),
      ]),
      _c("div", { staticClass: "font-weight-bold text-h6 mt-16" }, [
        _vm._v(_vm._s(_vm.$t("label.fillInTheForm"))),
      ]),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$t("label.personalData"))),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-form",
                { ref: "basicInfo" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              label: _vm.$t("label.fullNameAsPerNric"),
                              placeholder: _vm.$t("label.fullName"),
                              rules: _vm.$validation.nameRules(),
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              label: _vm.$t("label.nricNoMalaysianOnly"),
                              mask: "######-##-####",
                              placeholder: "------ - -- - ----",
                              rules:
                                _vm.$validation.malaysianIdentityNumberRules(),
                            },
                            model: {
                              value: _vm.form.nric,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nric", $$v)
                              },
                              expression: "form.nric",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              "item-text": "text",
                              "item-value": "uuid",
                              label: _vm.$t("label.monthlyIncome"),
                              items: _vm.monthlyIncomeDdl,
                              type: "select",
                              placeholder: _vm.$t("label.pleaseSelect"),
                              rules: _vm.$validation.requiredRules(),
                            },
                            model: {
                              value: _vm.form.monthlyIncome,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "monthlyIncome", $$v)
                              },
                              expression: "form.monthlyIncome",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              label: _vm.$t("label.financingAmount"),
                              prefix: "RM",
                              z: "",
                              placeholder: "eg: 10000",
                              rules: _vm.$validation.financingAmountRules(
                                5000,
                                200000
                              ),
                            },
                            model: {
                              value: _vm.form.loanAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loanAmount", _vm._n($$v))
                              },
                              expression: "form.loanAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              "item-text": "text",
                              "item-value": "uuid",
                              label: _vm.$t("label.financingTenure"),
                              items: _vm.loanTenureYearDdl,
                              type: "select",
                              placeholder: _vm.$t("label.pleaseSelect"),
                              rules: _vm.$validation.requiredRules(),
                            },
                            model: {
                              value: _vm.form.loanTenureYear,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loanTenureYear", $$v)
                              },
                              expression: "form.loanTenureYear",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4" } },
                        [
                          _c("app-form-field", {
                            attrs: {
                              label: _vm.$t("label.email"),
                              placeholder: "eg: abc@gmail.com",
                              rules: _vm.$validation.emailRules(),
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "mobileNumber",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("app-form-field", {
                            attrs: {
                              prefix: "+60",
                              z: "",
                              placeholder: "111 ---- ----",
                              label: _vm.$t("label.mobileNumber"),
                              rules: _vm.$validation.mobileNumberRules(),
                            },
                            model: {
                              value: _vm.form.mobileNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobileNumber", $$v)
                              },
                              expression: "form.mobileNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "tac",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("app-form-field", {
                            attrs: {
                              label: _vm.$t("label.requestTacNumber"),
                              placeholder: _vm.$t("label.enterSmsTacCode"),
                              "append-button": {
                                title:
                                  _vm.tacResendCountDown > 0
                                    ? _vm.$t("action.getCode") +
                                      " (" +
                                      this.tacResendCountDown +
                                      "S)"
                                    : _vm.$t("action.getCode"),
                                onClick: _vm.getTacCode,
                                disabled: _vm.tacResendCountDown > 0,
                              },
                              rules: _vm.$validation.tacRules(),
                            },
                            model: {
                              value: _vm.form.tacNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tacNumber", $$v)
                              },
                              expression: "form.tacNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-form",
        { ref: "agreementCheckBox" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "9", "offset-sm": "3" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      rules: [
                        function (v) {
                          return (
                            !!v ||
                            _vm.$t("validationMessage.youMustAgreeToContinue")
                          )
                        },
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "checkbox-label px-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("label.alliancePersonalLoanCheckbox1")
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      rules: [
                        function (v) {
                          return (
                            !!v ||
                            _vm.$t("validationMessage.youMustAgreeToContinue")
                          )
                        },
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "checkbox-label px-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("label.alliancePersonalLoanCheckbox2")
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      rules: [
                        function (v) {
                          return (
                            !!v ||
                            _vm.$t("validationMessage.youMustAgreeToContinue")
                          )
                        },
                      ],
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "checkbox-label px-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("label.alliancePersonalLoanCheckbox3")
                                )
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12", "offset-sm": "3" } },
        [
          _c(
            "div",
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("label.clickHereFor")) + _vm._s(" ")),
              ]),
              _vm._l(_vm.documents, function (document, i) {
                return [
                  _c(
                    "a",
                    {
                      key: document.name,
                      staticClass: "font-italic",
                      attrs: { href: document.link, target: "_blank" },
                    },
                    [_vm._v(_vm._s(document.name))]
                  ),
                  _c("span", {
                    key: document.name + "-separator",
                    domProps: {
                      textContent: _vm._s(
                        _vm.getSeparators(i, _vm.documents.length)
                      ),
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _c("app-button", {
            staticClass: "my-4",
            attrs: {
              title: _vm.$t("action.submitNow"),
              type: "default",
              action: _vm.onSubmitClick,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }